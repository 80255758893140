import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchDataSuccess, fetchDataError, FETCH_DATA } from './actions';
import { fetchHistoryDetail } from 'services/history.service';
import { PayloadAction } from '@reduxjs/toolkit';

function* fetchDataSaga(action: PayloadAction<any>): Generator<any, void, any> {

  const gameCode = action.payload.gameCode;
  if(gameCode !== "Solitario") {
    delete action.payload.playerId;
  }
  if (gameCode === "Solitario" && action.payload.isBO) { 
    if (!action.payload.playerId) {
      return
    }
  }
  try {
    const response = yield fetchHistoryDetail(action.payload);
    if (!response.data) {
      yield put(fetchDataError("Data is null"));
    } else {
      yield put(fetchDataSuccess({data: response.data, total: response.total}));
    }
  } catch (error: any) {
    yield put(fetchDataError(error.message));
  }
}

function* rootSaga() {
    yield takeEvery(FETCH_DATA, fetchDataSaga);
}

export default rootSaga;
