import { NotFound } from 'components/NotFound';
import { MatchReplay } from './components/MatchReplay';
import { Navigate, useRoutes } from 'react-router-dom';

export const Router = () =>
  useRoutes([
    {
      path: '/history',
      element: <MatchReplay />,
    },
    {
        path: '/404',
        element: <NotFound />,
    },
    { path: '*', element: <Navigate to="/history" replace /> },
  ]);
