export const NotFound = () => {
  return (
    <div
      className="w-[100vw] h-[100vh] not-found-main flex flex-col fixed z-[9999] text-[#fff] justify-center m-[0 auto] text-center"
      style={{
        backgroundImage: "url('/images/not-found/bg.jpg')",
        backgroundPosition: "50% 50%",
      }}
    >
      <div className="z-10 relative">
        <h2 
        className="font-bold text-[2.25rem]"
        style={{
            textShadow: '2px 2px 8px rgba(86,69,113,0.4)',
            letterSpacing: '2px'
        }}
        >Look like you're lost in space</h2>
        <img src="/images/not-found/404.png" alt="" className="lg:max-w-[570px] mx-[auto] mt-[20px] w-full max-w-[300px]" />
      </div>
    </div>
  );
};
