import common from "./common.json";
import scopa from "./scopa.json";
import tresette from './tresette.json'
import itPoker from './itPoker.json';
import setteEMezzo from './setteEMezzo.json';
import peppa from './peppa.json'
import scala40 from './scala40.json'
import burraco from './burraco.json'
import bestia from './bestia.json'
import solitario from './solitario.json'
import trentuno from './trentuno.json'

export default {
  common,
  scopa,
  tresette,
  itPoker,
  setteEMezzo,
  peppa,
  scala40,
  burraco,
  bestia,
  solitario,
  trentuno
};
