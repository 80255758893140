import axiosClient from './axiosClient';
const webApi = process.env.REACT_APP_API_URL;
const boApi = process.env.REACT_APP_BO_API_URL;

export const fetchTournamentDetail = async (id: string | number) => {
    try {
        const response = await axiosClient.get(`${webApi}/tournaments/${id}`);
        return response?.data;
    } catch (error) {
        throw new Error('Failed to fetch data from the API.');
    }
};

export const fetchTableDetail = async (id: string | number) => {
    try {
        const response = await axiosClient.get(`${webApi}/cashtables/${id}`);
        return response?.data;
    } catch (error) {
        throw new Error('Failed to fetch data from the API.');
    }
};

export const fetchRounds = async (params: any, isBO = false) => {
    const apiUrl = isBO ? boApi : webApi;
    try {
        const searchParams = new URLSearchParams(params);
        const response = await axiosClient.get(`${apiUrl}/history/search?${searchParams.toString()}`);
        return response?.data;
    } catch (error) {
        throw new Error('Failed to fetch data from the API.');
    };
};

export const fetchHistoryDetail = async (params: any) => {
    try {
        const { matchId, isBO, ...rest } = params;
        const apiUrl = isBO ? boApi : webApi;
        const searchParams = new URLSearchParams(rest);
        const response = await axiosClient.get(`${apiUrl}/history/details/${matchId}?${searchParams.toString()}`);
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch data from the API.');
    }
};

export const fetchGetTournamentByMatch = async (matchId: number) => {
    try {
        const response = await axiosClient.get(`${webApi}/matches/getTournamentByMatch/${matchId}`)
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch data from the API.');
    }
}
