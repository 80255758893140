export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';
export const UPDATE_PARAMS = 'UPDATE_PARAMS';

export const updateParams = (payload: any) => ({
    type: UPDATE_PARAMS,
    payload,
});

export const fetchData = (payload: any) => ({
    type: FETCH_DATA,
    payload: payload,
});

export const fetchDataSuccess = (data: any) => ({
    type: FETCH_DATA_SUCCESS,
    payload: data,
});

export const fetchDataError = (error: string) => ({
    type: FETCH_DATA_ERROR,
    payload: error,
});
