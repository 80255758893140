import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { configureStore } from '@reduxjs/toolkit';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create the Redux store with the rootReducer and apply the saga middleware
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware)
});

// Run the rootSaga to start listening for actions
sagaMiddleware.run(rootSaga);

export default store;
