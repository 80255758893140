import { useState } from "react";
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from "react-i18next";
import SelectCardType from "./SelectCardType";
import { GameHelper } from "@quanticogames/gameclient-core";
import SelectPlayer from "./selectPlayer";
import { useSearchParams } from "react-router-dom";

type tournamentInfoProps = {
    tournamentInfo: any;
    cardTypeList?: any;
    cardType?: string;
    setCardType?: (cardType: string) => void;
    players: any;
    currentPlayer: string;
    setCurrentPlayer: (player: any) => void;
}

const type = {
    Normal: 'Sit & Go',
    Crazy_Prize: 'Colpo Grosso',
    Treble: 'Filotto',
    Scheduled: 'Tournaments'
}

const TournamentInfo = (props: tournamentInfoProps) => {
    const [searchParams] = useSearchParams();
    const { tournamentInfo, cardTypeList, cardType, setCardType, players, currentPlayer, setCurrentPlayer } = props;
    const isMobile = GameHelper.isMobile();
    const { t } = useTranslation("common");
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const game = searchParams.get('game');
    return (
        <div className="tournament-info absolute right-0 text-[#fff]">
            <p
                className="tournament-info-text"
                onClick={() => setShowDropdown(!showDropdown)}
            >
                {/* <span className="tournament-info-txt mr-[2px]">{t("Tournament")} {t("Info")}</span>   */}
                <InfoIcon className="ml-[5px]"/>
            </p>
            {showDropdown && (
                <ul className="tournament-info-list">
                    <li className="">{t("Tournament Code")}: <strong>{tournamentInfo?.code}</strong></li>
                    <li className="">{t("Tournament Type")}: <strong>{type[tournamentInfo?.type]}</strong></li>
                    <li className="">{t("Cost")}: <strong>{(tournamentInfo?.cost) / 100} €</strong></li>
                    <li className="">{t("Total Prize")}: <strong>{(tournamentInfo?.prizePlan?.totalPrize) / 100 || 0} €</strong></li>
                    <li className="">{t("AAMS Code")}: <strong>{tournamentInfo?.aamsCode}</strong></li>
                    {!isMobile ? null : (
                        <>
                            <SelectCardType cardTypeList={cardTypeList} cardType={cardType} setCardType={setCardType} />
                            {game === 'Solitario' && <SelectPlayer listPlayer={players} player={currentPlayer} onSelectPlayer={setCurrentPlayer} />}
                        </>
                    )}
                </ul>
            )}
        </div>
    );
}
export default TournamentInfo