import { GameHelper, Player } from "@quanticogames/gameclient-core";
import { t } from "i18next";
import { Select, MenuItem } from "@mui/material";

type SelectPlayerProps = {
    listPlayer: any[];
    onSelectPlayer: (player: string) => void;
    player: string;
}

const SelectPlayer = (props: SelectPlayerProps) => {
    const { onSelectPlayer, listPlayer, player } = props;
    const playerList = listPlayer.map((player) => {
        return {
            value: player.userId,
            name: player?.userName
        }
    });
    const isMobile = GameHelper.isMobile();
   
    return (
        <div className={`${isMobile ? '': 'fixed top-[120px] left-[50px]'} flex flex-col select-card-box`}>
            <label className={`${isMobile ? 'text-black' : 'text-[#fff]' } mb-[10px] inline-block  font-bold text-[14px]`}>{t('Select User')}</label>
            <Select
                defaultValue={player}
                onChange={(e) => {
                    onSelectPlayer(e.target.value)
                }}
                className="bg-[#fff] block select-card-input cursor-pointer !border-none h-[40px] rounded-[2px] w-[200px] px-[10px] font-semibold"
                value={player}
            >
                {playerList.map(function (option, i) {
                    return <MenuItem key={i} value={option.value} className="min-h-[30px]">
                        {option.name}
                    </MenuItem>;
                })}
            </Select>
        </div>
    )

}
export default SelectPlayer