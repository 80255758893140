import { FETCH_DATA_SUCCESS, FETCH_DATA_ERROR, FETCH_DATA, UPDATE_PARAMS } from './actions';
import { AppState } from './state';

const initialState: AppState = {
    params: {
        matchId: null,
        roundId: null,
        interactionId: null,
        gameCode: null,
        playerId: 0,
    },
    loading: false,
    data: null,
    total: 0,
    error: null,
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_PARAMS:
            return {
                ...state,
                params: {
                    ...state.params,
                    ...action.payload,
                }
            };
        case FETCH_DATA:
            return {
                ...state,
                loading: true,
                data: null,
                error: null,
            };
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                total: action.payload.total,
                error: null,
            };
        case FETCH_DATA_ERROR:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
